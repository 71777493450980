import { EmployeeGenerateForm } from './employee-generate-form.model';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';
import { HiringStatusesEnum } from '../../../shared/models/others.models';

export class GetAllEmployees {
  static readonly type = '[Employees] Get all employees';

  constructor(public filters?: any) {}
}

export class UpdateAllEmployeesList {
  static readonly type = '[Employees] UpdateAllEmployeesList VETAL';

  constructor(
    public uid: string,
    public oldStatus: HiringStatusesEnum,
  ) {}
}

export class GenerateEmployee {
  static readonly type = '[Employees] Generate random employee';

  constructor(public employeeData: EmployeeGenerateForm) {}
}

export class OpenGenerateEmployee {
  static readonly type = '[Employees] Open generate popup';
}

export class ClearGeneratedEmployee {
  static readonly type = '[Employees] Clear generated employee';
}

export class DeleteEmployee {
  static readonly type = '[Employees] Delete employee';

  constructor(public uid: string) {}
}

export class GetSalesList {
  static readonly type = '[Employees] Get list of sales';

  constructor(
    public filterData?: {
      search: string;
      statuses: string[];
      creators: string[];
      date: string;
      page?: number;
      rows: number;
    },
  ) {}
}

export class GetRepresentativeListForFilter {
  static readonly type = '[Employees] Get list of representative for filter';

  constructor(public search?: string) {}
}

export class PostEmployee {
  static readonly type = '[Employee] Create new employee';

  constructor(public employeeData: any) {}
}

export class EditEmployee {
  static readonly type = '[Employee] Edit employee';

  constructor(public data: any) {}
}

export class CreateEmploee {
  static readonly type = '[Employee] Start employee creation';
}

export class SendEmail {
  static readonly type = '[Employee] Send email';

  constructor(public email: string) {}
}

export class SendMessage {
  static readonly type = '[Employee] Send sms';

  constructor(public phone: number) {}
}

export class DownloadDocument {
  static readonly type = '[Employee] Download document';

  constructor(
    public uid: string,
    public format: 'archive' | 'pdf',
    public historyId?: number,
  ) {}
}

export class PrintDocument {
  static readonly type = '[Employee] Print document';
}

export class ResetAdminPanel {
  static readonly type = '[Employee] Reset loading state';
}

export class HhaExchangeSync {
  static readonly type = '[Employee] Sync HHAeXchange';

  constructor(public userIds: string[]) {}
}

export class ChangeDates {
  static readonly type = '[Employee] Change createdAt';

  constructor(
    public uuid: string,
    public newDate: string,
    public typeDate?: string,
  ) {}
}

export class GetAttachments {
  static readonly type = '[Employee] Get attachments list (employee)';

  constructor(public key?: FiltersKeyEnum) {}
}

export class RefreshAttachmentsList {
  static readonly type = '[Employee] Refresh attachments list (employee)';
}

export class UpdateAttachment {
  static readonly type = '[Employee] Update an attachment';

  constructor(
    public attachmentId: string,
    public title: string,
  ) {}
}

export class DeleteAttachment {
  static readonly type = '[Employee] Delete an attachment (employee)';

  constructor(public attachmentId: number | string) {}
}

export class DownloadAttachment {
  static readonly type = '[Employee] Download an attachment';

  constructor(public attachmentIds: number | string | string[]) {}
}

export class DownloadMultipleAttachments {
  static readonly type = '[Employee] Download multiple attachments';

  constructor(public ids: string[]) {}
}

export class UploadDocument {
  static readonly type = '[Employee] Upload document';

  constructor(
    public title: string,
    public file: any,
  ) {}
}

export class SetNoteEmployee {
  static readonly type = '[Employee] Create note';

  constructor(
    public uid: string,
    public text: string,
  ) {}
}

export class DeleteNoteEmployee {
  static readonly type = '[Employee] Delete note';

  constructor(public uid: string) {}
}

export class GetUserListToAssignEmployee {
  static readonly type = '[Dashboard] Get Assign user';

  constructor(public search?: string) {}
}

export class SetAssignUserToEmployee {
  static readonly type = '[Dashboard] Assign user';

  constructor(
    public uid: string[],
    public assignIds: string[],
  ) {}
}

export class DeleteUserToAssignEmployee {
  static readonly type = '[Dashboard] Delete Assign user';

  constructor(
    public employeeId: string,
    public userId: string,
  ) {}
}

export class GetUserListToAssignFilterEmployee {
  static readonly type = '[Dashboard] Get Assign user for filter';

  constructor(public search?: string) {}
}

export class DashboardEnableDatatableLoading {
  static readonly type = '[Dashboard] EnableLoading';
}

export class GetAllMedicalAttachmentsEmployee {
  static readonly type = '[Medical document history] Get attachments list by UID';

  constructor(
    public uid: string,
    public isParent = 0,
  ) {}
}

export class DownloadMedicalAttachmentsEmployee {
  static readonly type = '[Medical document history] Get attachment  by UID';

  constructor(
    public uid: string,
    public attIds: string | string[],
  ) {}
}

export class UpdateMedicalAttachmentsEmployee {
  static readonly type = '[Medical document history] Update attachment UID';

  constructor(
    public uid: string,
    public attId: string,
    public title: string,
  ) {}
}

export class DeleteMedicalAttachmentsEmployee {
  static readonly type = '[Medical document history] Delete attachment  by UID';

  constructor(
    public uid: string,
    public attId: string,
  ) {}
}

export class SetEmployeeHiringStatuses {
  static readonly type = '[Employees] Set employee hiring statuses';

  constructor(
    public uid: string,
    public status: HiringStatusesEnum,
    public terminationDate?: string,
  ) {}
}

export class ChangeEmployeeTerminationDate {
  static readonly type = '[Employees] Change employee termination date';

  constructor(
    public uid: string,
    public terminationDate: string,
  ) {}
}

export class GetViventiumSyncStatusesList {
  static readonly type = '[Employees] Get Viventium Sync Statuses List';
}
